import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
const initialState = {
    currentItemIndex: -1,
    sharedArticleLocalSearchTerm: '',
    shouldMoveRowSelectionTo: 0,
};
const useReceiptItemTableStore = create()(immer((set) => ({
    ...initialState,
    setCurrentItemIndex: (currentItemIndex) => {
        set((state) => {
            state.currentItemIndex = currentItemIndex;
        });
    },
    setSharedArticleLocalSearchTerm: (searchTerm) => {
        set((state) => {
            state.sharedArticleLocalSearchTerm = searchTerm;
        });
    },
    setShouldMoveRowSelectionTo: (to) => {
        set((state) => {
            state.shouldMoveRowSelectionTo = to;
        });
    },
    resetReceiptItemsTableState: () => {
        set(initialState);
    },
})));
export { useReceiptItemTableStore };
