import { PackingType } from 'shared/models';
import { t } from 'shared/translations';
export const getPackingTypeNameById = (id) => {
    switch (id) {
        case PackingType.Sale:
            return t('selling.label');
        case PackingType.Return:
            return t('return.noun');
        case PackingType.ToStock:
            return t('on.stock.label');
        default:
            throw new Error(`Unexpected PackingType: '${id}'`);
    }
};
