import { Box, Chip, DialogContent, DialogTitle, TableRow, lighten, styled, tableRowClasses } from '@mui/material';
// TODO: (duplicated, PriceListMarkupTab)
export const ToolbarBox = styled(Box)(({ theme, alignItems }) => ({
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    alignItems: alignItems ?? 'flex-end',
    padding: '1rem',
    gap: '1rem',
}));
const linearGradientWithColor = (color) => `linear-gradient(90deg, ${color} 20%, rgba(255,255,255,1) 52%)`;
// TODO: to delete all comments below later, after gradient is approved.
export const ReceiptTableRow = styled(TableRow, {
    shouldForwardProp: (prop) => prop !== 'positionState' && prop !== 'selected',
})(({ theme, positionState, selected }) => {
    const { palette: { success, yellow, error, primary, common, background }, } = theme;
    let color = common.white;
    let hoverColor = primary.backgroundLight;
    // let hoverOutlineColor = primary.background;
    let borderColor = background.light;
    const coefficient = 0.3;
    switch (positionState) {
        case 'blocked':
            color = lighten(error.background, coefficient + 0.1);
            hoverColor = lighten(error.background, 0.1);
            borderColor = lighten(error.background, coefficient + 0.1);
            // color = linearGradientWithColor(error.lighter200);
            // hoverColor = linearGradientWithColor(error.lighter);
            break;
        case 'complete':
            color = lighten(success.background, coefficient);
            hoverColor = lighten(success.lighter200, 0.4);
            borderColor = lighten(success.background, coefficient);
            // color = linearGradientWithColor(success.lighter200);
            // hoverColor = linearGradientWithColor(success.lighter);
            break;
        case 'incomplete':
            color = lighten(yellow.background, coefficient);
            hoverColor = lighten(yellow.lightest, 0.4);
            borderColor = lighten(yellow.background, coefficient);
            // color = linearGradientWithColor(yellow.lightest);
            // hoverColor = linearGradientWithColor(yellow.lighter);
            break;
        default:
            // hoverColor = linearGradientWithColor(primary.backgroundLight);
            hoverColor = lighten(primary.backgroundLight, 0.5);
            break;
    }
    const outlineStyles = selected
        ? {
            outline: `2px solid ${theme.palette.primary.main}`,
            outlineOffset: '-2px',
            borderRadius: '6px',
        }
        : undefined;
    return {
        background: color,
        '> td': { borderTopColor: borderColor },
        [`&.${tableRowClasses.hover}&:hover`]: {
            cursor: 'pointer',
            background: hoverColor,
            '> td': { borderTopColor: hoverColor },
        },
        ...outlineStyles,
    };
});
export const QuantityChip = styled(Chip)(({ theme, color }) => ({
    minWidth: '2.25rem',
    backgroundColor: color === 'primary'
        ? lighten(theme.palette.primary.lighter200, 0.2)
        : color === 'secondary'
            ? lighten(theme.palette.secondary.lighter200, 0)
            : color === 'yellow'
                ? lighten(theme.palette.yellow.lightest, 0.1)
                : color,
    '.MuiChip-label': {
        lineHeight: '1rem',
        fontSize: theme.typography.caption.fontSize,
    },
}));
export const QuantityChipRevokable = styled(Chip)(({ theme, color }) => ({
    gap: '.25rem',
    backgroundColor: color === 'success'
        ? lighten(theme.palette.success.lighter200, 0)
        : color === 'warning'
            ? lighten(theme.palette.warning.lighter200, 0.1)
            : color === 'yellow'
                ? lighten(theme.palette.yellow.lightest, 0.1)
                : color === 'error'
                    ? lighten(theme.palette.error.lighter200, 0.2)
                    : color,
    '.MuiChip-label': {
        lineHeight: '1rem',
        fontSize: theme.typography.caption.fontSize,
        minWidth: '1.75rem',
        // If table cell has align left then the label inherits this align, should be fixed to right, near the x button.
        textAlign: 'right',
    },
}));
export const DialogTitleWithIcon = styled(DialogTitle)({
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '1rem',
});
export const GridX4 = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    padding: '.75rem 0',
    fontSize: theme.typography.body2.fontSize,
}));
export const GridColumnSpan3 = styled('div')({
    gridColumn: 'span 3 / span 3',
});
export const DialogContentStyled = styled(DialogContent)({
    position: 'relative',
    paddingBottom: '2rem',
});
