import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { createAppAsyncThunk } from 'warehouse/helpers';
import { getErrorFromException } from 'core/utils/errorHandling';
import { receiptApi } from '../receipt.api';
import { receiptSnackbarAutoHideDuration } from 'warehouse/constants';
import { t } from 'shared/translations';
import { useReceiptItemTableStore } from 'warehouse/modules/receipt/shared';
const revokeReceiptItem = createAppAsyncThunk('receipt/revokeReceiptItem', async ({ receiptItem, receiptItemName }, { getState, dispatch, rejectWithValue }) => {
    const mode = receiptItem.isBlocked ? 'unblock' : 'cancelReception';
    const { setSharedArticleLocalSearchTerm: setSearchTerm } = useReceiptItemTableStore.getState();
    try {
        // A single snackbar is allowed at one moment.
        closeSnackbar();
        await dispatch(receiptApi.endpoints.revokeReceiptItem.initiate(receiptItem)).unwrap();
        enqueueSnackbar({
            variant: 'success',
            header: mode === 'unblock' ? t('block.revoked.successfully') : t('receipt.revoked.successfully'),
            body: receiptItemName,
            autoHideDuration: receiptSnackbarAutoHideDuration,
        });
        // Clear search text field.
        setSearchTerm('');
    }
    catch (error) {
        let snackbarBody = getErrorFromException(error);
        // TODO: should be probably removed later when new backed will accept any block reason to unblock position.
        const responseError = error;
        if (responseError.StatusCode === 500 && responseError.Message?.includes("Pupupu some sticker can't be removed"))
            snackbarBody = mode === 'unblock' ? snackbarBody : t('block.reason.does.not.allow.to.unblock.item');
        enqueueSnackbar({
            variant: 'error',
            header: mode === 'unblock' ? t('block.revoke.failed') : t('receipt.revoke.failed'),
            body: snackbarBody,
            persist: true,
        });
        console.error('Receipt item accept error:', error);
        if (error instanceof Error) {
            return rejectWithValue(`Cannot revoke receipt item: ${error.message}`);
        }
        return rejectWithValue('Server unexpected error');
    }
});
export { revokeReceiptItem };
