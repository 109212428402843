import barcodeReadIcon from 'core/svg/fa/barcode-read.svg';
import boxOpenIcon from 'core/svg/fa/box-open.svg';
import boxesStackedIcon from 'core/svg/fa/boxes-stacked.svg';
import chartTreeMapIcon from 'core/svg/fa/chart-tree-map.svg';
import dollyIcon from 'core/svg/fa/dolly.svg';
import gearIcon from 'core/svg/fa/gear.svg';
import shelvesIcon from 'core/svg/fa/shelves.svg';
import { t } from 'shared/translations';
export const sidebarNavigationItems = [
    {
        sectionName: t('crossdocking.label'),
        menuItems: [
            {
                title: t('receipt.label'),
                linkTo: '/receipt/cross',
                icon: barcodeReadIcon,
            },
            {
                title: t('placement.label'),
                linkTo: '/placement',
                // icon: faBoxesPacking, // originally fa-arrow-down-arrow-up (only in pro).
                icon: shelvesIcon, // originally fa-arrow-down-arrow-up (only in pro).
            },
            {
                title: t('packing.label'),
                linkTo: '/packing',
                icon: boxOpenIcon,
            },
            {
                title: t('shipment.label'),
                linkTo: '/shipment',
                icon: dollyIcon, // faForklift also fits
            },
        ],
    },
    {
        sectionName: t('stock.label'),
        menuItems: [
            {
                title: t('receipt.label'),
                linkTo: '/receipt/stock',
                icon: barcodeReadIcon,
            },
            {
                title: t('warehouses.label'),
                linkTo: 'stock',
                icon: boxesStackedIcon, // <i class="fa-duotone fa-solid fa-shelves-empty"></i>
            },
            {
                title: t('warehouse.addresses.label'),
                linkTo: '/storage',
                icon: chartTreeMapIcon, //  faCubesStacked - is old variant.
            },
        ],
    },
    {
        sectionName: t('settings.label'),
        menuItems: [
            {
                title: t('settings.label'),
                linkTo: '/settings',
                icon: gearIcon,
            },
        ],
    },
];
