import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { TableVirtuoso, } from 'react-virtuoso';
import { TableHead as MuiTableHead, Table, TableBody, TableContainer, styled } from '@mui/material';
import { TableBodyEmpty, TableHeadRow, TableRowCells } from 'shared/components';
import { getPositionState, getReceiptItemAvailableQuantity } from './helpers';
import { CircularProgressBackdrop } from 'core/ui';
import { HideScrollerEdgeContainer } from 'core/styled';
import { ReceiptTableRow } from './shared.styled';
import { catchInsideWindowKeyDownEventClassName } from './constants';
import { closeSnackbar } from 'notistack';
import { engRusLetterOrNumberOnlyRegex } from 'core/utils/validation';
import { useEventListener } from 'usehooks-ts';
import { useReceiptItemTableStore } from './ReceiptItemTable.state';
const ReceiptItemTable = ({ data, isLoading, isFetching, isError, error, columns, fieldToSearchBy, itemContent, onRowDoubleClick, onEnterKeyDown, onDeleteKeyDown, onMinusKeyDown, onPlusKeyDown, onLetterOrNumberKeyDown, }) => {
    // const dispatch = useAppDispatch();
    // const shouldMoveRowSelectionTo = useAppSelector((state) => state.receipt.shouldMoveRowSelectionTo);
    // const searchTerm = useAppSelector((state) => state.receipt.sharedArticleLocalSearchTerm);
    // const currentItemIndex = useAppSelector((state) => state.receipt.currentItemIndex);
    const shouldMoveRowSelectionTo = useReceiptItemTableStore((state) => state.shouldMoveRowSelectionTo);
    const searchTerm = useReceiptItemTableStore((state) => state.sharedArticleLocalSearchTerm);
    const currentItemIndex = useReceiptItemTableStore((state) => state.currentItemIndex);
    const setShouldMoveRowSelectionTo = useReceiptItemTableStore((state) => state.setShouldMoveRowSelectionTo);
    const setCurrentItemIndex = useReceiptItemTableStore((state) => state.setCurrentItemIndex);
    const [isTableFocused, setIsTableFocused] = useState(false);
    const tableVirtuosoRef = useRef(null);
    const listRef = useRef(null);
    // When table is out of focus and currentItemPosition is selected it is now visible, and if the user select another item with mouse click
    // on a row then selected position rectangle is shown first, right before new position is selected and rectangle moves to new selected position.
    // The following const is used to prevent set isFocused flag onMouseDown, before onFocus, and set in later in onMouseClick event.
    const tableIsFocusedOnMouseDown = useRef(false);
    const rowCount = data?.length ?? 0;
    useEffect(() => {
        return () => {
            // // Prevents `currentItemIndex` persist it's value even when there is already another table is shown.
            // dispatch(receiptActions.setCurrentItemIndex(-1));
            // Prevents `currentItemIndex` persist it's value even when there is already another table is shown.
            setCurrentItemIndex(-1);
            closeSnackbar();
        };
        // }, [dispatch]);
    }, [setCurrentItemIndex]);
    const handleWindowKeyDown = (event) => {
        if (!((event.code === 'ArrowUp' || event.code === 'ArrowDown') &&
            (event.target?.tagName === 'BODY' ||
                event.target?.classList.contains(catchInsideWindowKeyDownEventClassName))))
            return;
        // console.log('event', event);
        // console.log('event.target', event.target);
        event.preventDefault();
        // Important, prevents event from fire twice.
        event.stopImmediatePropagation();
        listRef?.current?.focus();
        // dispatch(receiptActions.setShouldMoveRowSelectionTo(event.code === 'ArrowUp' ? -1 : 1));
        setShouldMoveRowSelectionTo(event.code === 'ArrowUp' ? -1 : 1);
    };
    useEventListener('keydown', handleWindowKeyDown);
    useEffect(() => {
        if (isLoading || rowCount === 0)
            return;
        listRef.current?.focus();
    }, [isLoading, rowCount]);
    // TODO: to delete
    // console.log('currentItemIndex', currentItemIndex);
    useEffect(() => {
        if (shouldMoveRowSelectionTo === 0)
            return;
        let newIndex = currentItemIndex + shouldMoveRowSelectionTo;
        if (newIndex < 0)
            newIndex = 0;
        // dispatch(receiptActions.setShouldMoveRowSelectionTo(0));
        setShouldMoveRowSelectionTo(0);
        // TODO: to delete
        // console.log('before setCurrentItemIndex, newINDEX555:', newIndex);
        // dispatch(receiptActions.setCurrentItemIndex(newIndex));
        setCurrentItemIndex(newIndex);
        listRef.current?.focus();
        // We should call `scrollToIndex` along with `setCurrentItemIndex` call not to force table lagging.
        tableVirtuosoRef.current?.scrollToIndex({ index: newIndex, align: 'center', behavior: 'smooth' });
        // Only shouldMoveRowSelectionTo change is handled here.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldMoveRowSelectionTo, setCurrentItemIndex]);
    // }, [shouldMoveRowSelectionTo, dispatch]);
    const moveToPositionBySearchTerm = useCallback(() => {
        if (!data || !searchTerm || !fieldToSearchBy)
            return;
        // Should locate only not fully receipted items.
        // Data should not be filtered by `availableQuantity` first since in this case the itemIndex will be 'broken' - it will be
        // an index of **filtered** items, but we need an index of **all** items.
        const itemIndex = data.findIndex((item) => getReceiptItemAvailableQuantity(item) > 0 && item[fieldToSearchBy].indexOf(searchTerm) !== -1);
        // dispatch(receiptActions.setCurrentItemIndex(itemIndex));
        setCurrentItemIndex(itemIndex);
        tableVirtuosoRef.current?.scrollToIndex({ index: itemIndex === -1 ? 0 : itemIndex, align: 'center', behavior: 'auto' });
        // }, [data, fieldToSearchBy, searchTerm, dispatch]);
    }, [data, fieldToSearchBy, searchTerm, setCurrentItemIndex]);
    useEffect(() => {
        if (!searchTerm) {
            return;
        }
        moveToPositionBySearchTerm();
        // `moveToPositionBySearchTerm` is excluded intentionally, since it is changed every time `data` is changed, and `data` is changed on every
        // reception or blocking. That forces `currentItemIndex` to be set to -1 as a result of which focus is lost on dialog submission.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);
    // }, [searchTerm, dispatch]);
    const getCurrentItem = useCallback(() => {
        if (!data || currentItemIndex === -1)
            return undefined;
        return data[currentItemIndex];
    }, [currentItemIndex, data]);
    const keyDownCallback = useCallback((event) => {
        let nextIndex = null;
        // TODO: to delete
        // console.log('event.code', event.code);
        // console.log('event.key', event.key);
        // console.log('test', engLettersAndNumbersOnlyRegex.test(event.key));
        if (event.code === 'Enter' && onEnterKeyDown) {
            const currentItem = getCurrentItem();
            // Pass through even if currentItem is undefined to play error sound.
            onEnterKeyDown(currentItem);
            return;
        }
        if (event.code === 'Delete' && onDeleteKeyDown) {
            const currentItem = getCurrentItem();
            if (currentItem)
                onDeleteKeyDown(currentItem);
            return;
        }
        if ((event.code === 'Equal' || event.code === 'NumpadAdd') && onPlusKeyDown) {
            // For +/=
            const currentItem = getCurrentItem();
            if (currentItem)
                onPlusKeyDown(currentItem);
            return;
        }
        if ((event.code === 'Minus' || event.code === 'NumpadSubtract') && onMinusKeyDown) {
            // For -/_
            const currentItem = getCurrentItem();
            if (currentItem)
                onMinusKeyDown(currentItem);
            return;
        }
        if (onLetterOrNumberKeyDown && (event.key === 'Backspace' || engRusLetterOrNumberOnlyRegex.test(event.key))) {
            onLetterOrNumberKeyDown(event);
            return;
        }
        if (event.code === 'ArrowUp') {
            nextIndex = Math.max(0, currentItemIndex - 1);
        }
        else if (event.code === 'ArrowDown') {
            nextIndex = Math.min(rowCount - 1, currentItemIndex + 1);
        }
        if (nextIndex !== null) {
            tableVirtuosoRef.current?.scrollIntoView({
                index: nextIndex,
                behavior: 'auto',
                done: () => {
                    // dispatch(receiptActions.setCurrentItemIndex(nextIndex));
                    setCurrentItemIndex(nextIndex);
                },
            });
            event.preventDefault();
        }
    }, 
    // TODO: later. this is working solution.
    // [currentItemIndex, tableVirtuosoRef, rowCount, setCurrentItemIndex, getCurrentItem]
    // [currentItemIndex, rowCount, getCurrentItem, onDeleteKeyDown, onEnterKeyDown, onLetterOrNumberKeyDown, onMinusKeyDown, onPlusKeyDown, dispatch]
    [
        currentItemIndex,
        rowCount,
        getCurrentItem,
        onDeleteKeyDown,
        onEnterKeyDown,
        onLetterOrNumberKeyDown,
        onMinusKeyDown,
        onPlusKeyDown,
        setCurrentItemIndex,
    ]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const scrollerRef = useCallback((element) => {
        if (element) {
            element.addEventListener('keydown', keyDownCallback);
            listRef.current = element;
        }
        else {
            listRef.current?.removeEventListener('keydown', keyDownCallback);
        }
    }, [keyDownCallback]);
    const Scroller = useCallback(forwardRef(({ children, context, ...props }, ref) => (_jsx(TableContainer, { ...props, ref: ref, onMouseDown: () => {
            tableIsFocusedOnMouseDown.current = true;
        }, onFocus: () => {
            // // TODO: to delete
            // console.log('in Scroller before setCurrentItemIndex(0)');
            // if (context!.currentItemIndex === -1) dispatch(receiptActions.setCurrentItemIndex(0));
            if (context.currentItemIndex === -1)
                setCurrentItemIndex(0);
            if (!context.isTableFocused && !tableIsFocusedOnMouseDown.current) {
                setIsTableFocused(true);
            }
            tableIsFocusedOnMouseDown.current = false;
        }, onBlur: () => {
            if (context.isTableFocused)
                setIsTableFocused(false);
        }, children: children }))), 
    // Important! Must be empty not to lose focus. Do not use any dependencies.
    []);
    // const Table:ComponentType<TableProps & { context?: ContextProps<T> }> = useCallback(({ context, ...props }: TableProps & {context?: ContextProps<T>}) => <Table stickyHeader {...props} sx={{ borderCollapse: 'separate', userSelect: 'none' }} />, [])
    const table = useCallback(
    // ({ context, ...props }) => <Table stickyHeader {...props} sx={{ borderCollapse: 'separate', userSelect: 'none', tableLayout: 'fixed' }} />,
    ({ context, ...props }) => _jsx(TableStyled, { stickyHeader: true, ...props }), []);
    // Used instead just 'TableHead: MuiTableHead' only to extract context to prevent context object to pass to the DOM.
    const TableHead = forwardRef(({ context, ...props }, ref) => (_jsx(MuiTableHead, { ...props, ref: ref })));
    const TableRow = useCallback(({ item: receiptItem, context, ...props }) => {
        // const { currentItemIndex, setCurrentItemIndex, onRowDoubleClick } = context!;
        const { currentItemIndex: contextCurrentItemIndex, 
        // setCurrentItemIndex: contextSetCurrentItemIndex,
        isTableFocused: contextIsTableFocused, } = context;
        // TODO: to delete later
        // console.log('isTableFocused:', contextIsTableFocused);
        return (_jsx(ReceiptTableRow, { hover: true, ...props, 
            // positionState={getPositionState(receiptItem.quantity, receiptItem.receiptedQuantity, receiptItem.blockedQuantity)}
            positionState: getPositionState(receiptItem.quantity, receiptItem.receiptedQuantity, 'blockedQuantity' in receiptItem ? receiptItem.blockedQuantity : undefined), 
            // TODO: to delete later. This is good working solution.
            // selected={contextCurrentItemIndex === props['data-item-index'] && contextIsTableFocused}
            selected: contextCurrentItemIndex === props['data-item-index'], onClick: () => {
                // dispatch(receiptActions.setCurrentItemIndex(props['data-item-index']));
                setCurrentItemIndex(props['data-item-index']);
                // isTableFocused flag could be not set to 'true' in onFocus event if it was blocked in onMouseDown event to avoid
                // showing selected rectangle in old position before new position is selected. That's why it is necessary to set it here.
                if (!contextIsTableFocused)
                    setIsTableFocused(true);
            }, onDoubleClick: () => onRowDoubleClick?.(receiptItem) }));
    }, 
    // TODO: to delete later
    // [getPositionState, onRowDoubleClick] // Old working solution, but seems to be unnecessary
    // [onRowDoubleClick, dispatch]
    [onRowDoubleClick, setCurrentItemIndex]);
    const tableBody = useCallback(forwardRef(({ children, context, ...props }, ref) => {
        const { isLoading = true, isError = false, error, isEmpty = false } = context ?? {};
        return (_jsxs(TableBody, { ...props, ref: ref, children: [_jsx(TableBodyEmpty, { isLoading: isLoading, isError: isError, error: error, columnsCount: columns.length, isEmpty: isEmpty }), !isLoading && children] }));
    }), [columns.length]);
    const fixedHeaderContent = useCallback(() => _jsx(TableHeadRow, { columns: columns }), [columns]);
    const rowContent = useCallback((index, item, { currentItemIndex }) => _jsx(TableRowCells, { columns: columns, item: item, rowNumber: index + 1 }), [columns]);
    return (_jsxs(HideScrollerEdgeContainer, { children: [_jsx(TableVirtuoso, { ref: tableVirtuosoRef, scrollerRef: scrollerRef, data: data, context: {
                    //columns,
                    isTableFocused,
                    currentItemIndex,
                    // setCurrentItemIndex,
                    isLoading,
                    isFetching,
                    isError,
                    error,
                    isEmpty: !rowCount,
                }, 
                // components={VirtuosoTableComponents}
                components: {
                    Scroller: Scroller,
                    Table: table,
                    TableHead: TableHead,
                    TableRow: TableRow,
                    TableBody: tableBody,
                }, fixedHeaderContent: fixedHeaderContent, itemContent: itemContent ?? rowContent, tabIndex: 0 }), _jsx(CircularProgressBackdrop, { corners: "straight", open: isFetching && !isLoading })] }));
};
export { ReceiptItemTable };
const TableStyled = styled(Table)({ borderCollapse: 'separate', userSelect: 'none' });
