import { SignalrConnectionHub, signalrHubConnector } from 'warehouse/signalR';
import { baseApiQuery, providesList } from 'shared/helpers/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { placementActions } from './placement.state';
import { showApiErrorSnackbar } from 'shared/helpers/thunk';
import { t } from 'shared/translations';
export const placementApi = createApi({
    reducerPath: 'api/placement',
    tagTypes: ['Placement', 'Cells'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getReceiptedPlacementItems: builder.query({
            query: (body) => ({
                method: 'POST',
                url: 'placement',
                body: { ...body, isPlaced: false },
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    const result = await queryFulfilled;
                    dispatch(placementActions.setReceiptedItemCount(result.data?.length ?? 0));
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.placement.items.request.failed'), error }));
                }
            },
            providesTags: (result) => providesList(result, 'Placement', 'stickerId'),
            /** Updates receipted placement items list according to socket notifications. */
            onCacheEntryAdded: async (arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }) => {
                const { addEventListener, events } = signalrHubConnector(SignalrConnectionHub.Placement);
                try {
                    await cacheDataLoaded;
                    const stickerPlacedEventListener = (sticker) => {
                        updateCachedData((draft) => {
                            const stickerIndex = draft.findIndex((item) => item.stickerId === sticker.stickerId);
                            if (stickerIndex === -1)
                                return;
                            // To delete this single line when following below workaround will be moved to `getPlacedPlacementItems` query.
                            const stickerItem = draft.find((item) => item.stickerId === sticker.stickerId);
                            draft.splice(stickerIndex, 1);
                            dispatch(placementActions.setReceiptedItemCount(draft.length));
                            // We do not have enough properties in socket message (StickerPlacedSocketMessage) returned by 'StickerPlaced' event to update
                            // draft in 'getPlacedPlacementItems' with message data,  that's why we have to do following workaround.
                            // We could have done it if 'StickerPlaced' event return 'StickerItemBase' props in message.
                            // 'Move' removed sticker to not blocked placed stickers by adding it to the start of the list.
                            dispatch(placementApi.util.updateQueryData('getPlacedPlacementItems', { isBlocked: false }, (placedDraft) => {
                                Object.assign(placedDraft, [stickerItem, ...placedDraft]);
                            }));
                        });
                    };
                    const stickerCreatedEventListener = (sticker) => {
                        // TODO: to delete
                        console.log('in stickerCreatedEventListener, message:', sticker);
                        // TODO: to delete
                        console.log('in stickerCreatedEventListener, arg:', arg);
                        updateCachedData((draft) => {
                            // // TODO: to delete
                            // console.log('getInvoices, onCacheEntryAdded in updateCachedData, current(draft):', current(draft));
                            // console.log('getInvoices, onCacheEntryAdded in updateCachedData, message:', message);
                            // console.log('getInvoices, onCacheEntryAdded in updateCachedData, mess.stockReceiptItemId:', message.stickerId);
                            // const stickerIndex = draft.findIndex((item) => item.stickerId === message.stickerId);
                            // if (stickerIndex === -1) return;
                            // draft.splice(stickerIndex, 1);
                        });
                    };
                    const stickerRemovedEventListener = (sticker) => {
                        // TODO: to delete
                        console.log('in stickerRemovedEventListener, message:', sticker);
                        // TODO: to delete
                        console.log('in stickerRemovedEventListener, arg:', arg);
                        updateCachedData((draft) => {
                            // // TODO: to delete
                            // console.log('getInvoices, onCacheEntryAdded in updateCachedData, current(draft):', current(draft));
                            // console.log('getInvoices, onCacheEntryAdded in updateCachedData, message:', message);
                            // console.log('getInvoices, onCacheEntryAdded in updateCachedData, mess.stockReceiptItemId:', message.stickerId);
                            // const stickerIndex = draft.findIndex((item) => item.stickerId === message.stickerId);
                            // if (stickerIndex === -1) return;
                            // draft.splice(stickerIndex, 1);
                        });
                    };
                    const stickerBlockedEventListener = (sticker) => {
                        // TODO: to delete
                        console.log('in stickerBlockedEventListener, message:', sticker);
                        // TODO: to delete
                        console.log('in stickerBlockedEventListener, arg:', arg);
                        updateCachedData((draft) => {
                            // // TODO: to delete
                            // console.log('getInvoices, onCacheEntryAdded in updateCachedData, current(draft):', current(draft));
                            // console.log('getInvoices, onCacheEntryAdded in updateCachedData, message:', message);
                            // console.log('getInvoices, onCacheEntryAdded in updateCachedData, mess.stockReceiptItemId:', message.stickerId);
                            // const stickerIndex = draft.findIndex((item) => item.stickerId === message.stickerId);
                            // if (stickerIndex === -1) return;
                            // draft.splice(stickerIndex, 1);
                        });
                    };
                    const stickerUnblockedEventListener = (sticker) => {
                        // TODO: to delete
                        console.log('in stickerUnblockedEventListener, message:', sticker);
                        // TODO: to delete
                        console.log('in stickerUnblockedEventListener, arg:', arg);
                        updateCachedData((draft) => {
                            // // TODO: to delete
                            // console.log('getInvoices, onCacheEntryAdded in updateCachedData, current(draft):', current(draft));
                            // console.log('getInvoices, onCacheEntryAdded in updateCachedData, message:', message);
                            // console.log('getInvoices, onCacheEntryAdded in updateCachedData, mess.stockReceiptItemId:', message.stickerId);
                            // const stickerIndex = draft.findIndex((item) => item.stickerId === message.stickerId);
                            // if (stickerIndex === -1) return;
                            // draft.splice(stickerIndex, 1);
                        });
                    };
                    const stickerAssembledEventListener = (sticker) => {
                        // // TODO: to delete
                        // console.log('in stickerPickedEventListener1, message:', message);
                        // // TODO: to delete
                        // console.log('in stickerPickedEventListener1, arg:', arg);
                        updateCachedData((draft) => {
                            // TODO: to delete
                            // console.log('getReceiptedPlacementItems, onCacheEntryAdded in updateCachedData, current(draft):', current(draft));
                            // console.log('getReceiptedPlacementItems, onCacheEntryAdded in updateCachedData, message:', message);
                            // console.log('getReceiptedPlacementItems, onCacheEntryAdded in updateCachedData, mess.stickerId:', message.stickerId);
                            // console.log('getReceiptedPlacementItems, onCacheEntryAdded in updateCachedData, mess.stickerItemId:', message.stickerItemId);
                            const stickerIndex = draft.findIndex((item) => item.stickerId === sticker.stickerId);
                            if (stickerIndex === -1)
                                return;
                            draft.splice(stickerIndex, 1);
                        });
                    };
                    const stickerRemovedAwayEventListener = (sticker) => {
                        // // TODO: to delete
                        // console.log('in stickerPutoutEventListener, message:', message);
                        // // TODO: to delete
                        // console.log('in stickerPutoutEventListener, arg:', arg);
                        updateCachedData((draft) => {
                            // // TODO: to delete
                            // console.log('getInvoices, onCacheEntryAdded in updateCachedData, current(draft):', current(draft));
                            // console.log('getInvoices, onCacheEntryAdded in updateCachedData, message:', message);
                            // console.log('getInvoices, onCacheEntryAdded in updateCachedData, mess.stockReceiptItemId:', message.stickerId);
                            if (draft.some((item) => item.stickerId === sticker.stickerId))
                                return;
                            draft.unshift(sticker);
                        });
                    };
                    addEventListener('StickerPlaced', stickerPlacedEventListener);
                    addEventListener('StickerCreated', stickerCreatedEventListener);
                    addEventListener('StickerRemoved', stickerRemovedEventListener);
                    addEventListener('StickerBlocked', stickerBlockedEventListener);
                    addEventListener('StickerUnblocked', stickerUnblockedEventListener);
                    addEventListener('StickerPicked', stickerAssembledEventListener);
                    addEventListener('StickerPutouted', stickerRemovedAwayEventListener);
                }
                catch {
                    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
                    // in which case `cacheDataLoaded` will throw
                }
                await cacheEntryRemoved;
            },
        }),
        getPlacedPlacementItems: builder.query({
            query: (body) => ({
                method: 'POST',
                url: 'placement',
                body: { ...body, isPlaced: true },
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    const result = await queryFulfilled;
                    dispatch(placementActions.setPlacedItemCount(result.data?.length ?? 0));
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.placement.items.request.failed'), error }));
                }
            },
            providesTags: (result) => providesList(result, 'Placement', 'stickerId'),
            onCacheEntryAdded: async (arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
                const { addEventListener, events } = signalrHubConnector(SignalrConnectionHub.Placement);
                try {
                    await cacheDataLoaded;
                    // TODO: to delete later (it is already handled in getReceiptedPlacementItems onCacheEntryAdded)
                    // const stickerPlacedEventListener: ConnectionHubListenerEvent<'StickerPlaced'> = (sticker) => {
                    // 	updateCachedData((draft) => {
                    // 		// // TODO: to delete
                    // 		// console.log('getInvoices, onCacheEntryAdded in updateCachedData, current(draft):', current(draft));
                    // 		// console.log('getInvoices, onCacheEntryAdded in updateCachedData, message:', message);
                    // 		// console.log('getInvoices, onCacheEntryAdded in updateCachedData, mess.stockReceiptItemId:', message.stickerId);
                    // 		if (draft.some((item) => item.stickerId === sticker.stickerId)) return;
                    // 		// TODO: we need to have StickerItem full list of field here. But only have `StickerPlacedSocketMessage` fields.
                    // 		// draft.unshift(sticker);
                    // 		// TODO: (also) check if tab counter has been updated
                    // 	});
                    // };
                    const stickerAssembledEventListener = (sticker) => {
                        updateCachedData((draft) => {
                            const stickerIndex = draft.findIndex((item) => item.stickerId === sticker.stickerId);
                            if (stickerIndex === -1)
                                return;
                            draft.splice(stickerIndex, 1);
                        });
                    };
                    const stickerRemovedAwayEventListener = (sticker) => {
                        updateCachedData((draft) => {
                            if (draft.some((item) => item.stickerId === sticker.stickerId))
                                return;
                            draft.unshift(sticker);
                        });
                    };
                    // TODO: to delete lager commented
                    // addEventListener<SignalrConnectionHub.Placement>('StickerPlaced', stickerPlacedEventListener);
                    addEventListener('StickerPicked', stickerAssembledEventListener);
                    addEventListener('StickerPutouted', stickerRemovedAwayEventListener);
                }
                catch {
                    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
                    // in which case `cacheDataLoaded` will throw
                }
                await cacheEntryRemoved;
            },
        }),
        getPlacementCells: builder.query({
            query: () => ({
                method: 'GET',
                url: 'placement/cells',
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    // dispatch(placementActions.setPlacedItemCount(result.data?.length ?? 0));
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.placement.cells.request.failed'), error }));
                }
            },
            providesTags: (result) => [{ type: 'Cells', id: 'LIST' }],
        }),
        blockUnblockSticker: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: 'sticker/block',
                body,
            }),
            invalidatesTags: (result, error, { stickerId }) => (error ? [] : [{ type: 'Placement', id: stickerId }]),
        }),
        assignStickerPlace: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: 'placement/place',
                body,
            }),
            onQueryStarted: async ({ stickerId }, { queryFulfilled, dispatch }) => {
                await queryFulfilled;
                // On mutation request is finished, run invalidation for 3 query requests without await that blocks the thread,
                // since `invalidatesTags` forces to await main request along with all invalidation requests.
                dispatch(placementApi.util.invalidateTags([{ type: 'Placement', id: stickerId }, { type: 'Cells' }]));
            },
            // invalidatesTags: (result, error, { stickerId }) => (error ? [] : [{ type: 'Placement', id: stickerId }, { type: 'Cells' }]),
        }),
    }),
});
export const { useGetReceiptedPlacementItemsQuery, useGetPlacedPlacementItemsQuery, useGetPlacementCellsQuery, useBlockUnblockStickerMutation, useAssignStickerPlaceMutation, } = placementApi;
