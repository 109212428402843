import { SignalrConnectionHub, signalrHubConnector } from 'warehouse/signalR';
import { baseApiQuery, providesList } from 'shared/helpers/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { showApiErrorSnackbar } from 'shared/helpers/thunk';
import { stockReceiptActions } from './stockReceipt.state';
import { t } from 'shared/translations';
/** Count of rows that should be rendered inside Invoice table to prevent lagging. */
const maxInvoiceRowCount = 100;
const api = createApi({
    reducerPath: 'api/receipt/stock',
    tagTypes: ['Invoices', 'StockReceiptItems'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getStockInvoices: builder.query({
            query: (body) => ({
                url: 'stock/invoices',
                method: 'POST',
                body,
            }),
            providesTags: (invoices) => providesList(invoices, 'Invoices', 'stockInvoiceId'),
            /** Updates stock invoice's `receiptedQuantity` field on socket notification. */
            onCacheEntryAdded: async (arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
                const { addEventListener, events } = signalrHubConnector(SignalrConnectionHub.Stock);
                try {
                    await cacheDataLoaded;
                    const listener = (message) => {
                        updateCachedData((draft) => {
                            const invoiceIndex = draft.findIndex((invoice) => invoice.stockInvoiceId === message.stockInvoice.stockInvoiceId);
                            if (invoiceIndex === -1)
                                return;
                            draft[invoiceIndex].receiptedQuantity = message.stockInvoice.receiptedQuantity;
                        });
                    };
                    addEventListener('Placement', listener);
                }
                catch {
                    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
                    // in which case `cacheDataLoaded` will throw
                }
                await cacheEntryRemoved;
            },
        }),
        getStockReceiptItems: builder.query({
            query: ({ stockInvoiceId, verification }) => ({
                // TODO: later (when new backend is done) - we actually need only InvoiceInfo fields but got all Invoice fields.
                url: 'stock/receiptItems',
                method: 'POST',
                // Different queries: for Verification tab - only `IsAccepted` and `IsBlocked` flags are valid; for Invoice receiptItems - only stockInvoiceId.
                body: { ...(verification ? { IsAccepted: false, IsBlocked: false } : { stockInvoiceId }) },
            }),
            onQueryStarted: async ({ stockInvoiceId, verification }, { dispatch, queryFulfilled }) => {
                try {
                    const result = await queryFulfilled;
                    // if (stockInvoiceId) dispatch(stockReceiptActions.setVerificationItemCount(result.data.length));
                    if (verification)
                        dispatch(stockReceiptActions.setVerificationItemCount(result.data.length));
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.stock.receipt.items.request.failed'), error }));
                }
            },
            providesTags: (receiptItems) => providesList(receiptItems, 'StockReceiptItems', 'stockReceiptItemId'),
            /** Updates stockReceiptItem's `receiptedQuantity` field on socket notification. */
            onCacheEntryAdded: async (arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
                const { addEventListener, events } = signalrHubConnector(SignalrConnectionHub.Stock);
                try {
                    await cacheDataLoaded;
                    const listener = (message) => {
                        updateCachedData((draft) => {
                            const receiptItemIndex = draft.findIndex((item) => item.stockReceiptItemId === message.receiptItem.stockReceiptItemId);
                            if (receiptItemIndex === -1)
                                return;
                            draft[receiptItemIndex].receiptedQuantity = message.receiptItem.receiptedQuantity;
                        });
                    };
                    addEventListener('Placement', listener);
                }
                catch {
                    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
                    // in which case `cacheDataLoaded` will throw
                }
                await cacheEntryRemoved;
            },
        }),
        // TODO: check if it is used
        getStockCells: builder.query({
            query: () => ({
                method: 'GET',
                url: 'stock/cells',
            }),
        }),
        /** TODO: old backend bug.
         * In case of error the endpoint still returns StatusCode 200 - OK! But result is populated with object:
         * (Example:) {
         * "success": false,
         * "error": "ячейка не принадлежит текущему стоку"
         * }
         */
        placeInStock: builder.mutation({
            // TODO: to return when new backend is done! We do not need `queryFn` here, queryFn only converts OK result with error to BAD result
            // query: (body) => ({
            // 	method: 'POST',
            // 	url: 'stock/place',
            // 	body,
            // }),
            queryFn: async (body, { dispatch }, extraOptions, baseQuery) => {
                const task = baseQuery({ method: 'POST', url: 'stock/place', body });
                const result = await task;
                const apiResult = result.data;
                if (!apiResult.success) {
                    return { error: { status: 500, data: apiResult.error } };
                }
                return { data: apiResult };
            },
            invalidatesTags: (result, error, { stockReceiptItemId }) => (error ? [] : [{ type: 'StockReceiptItems', id: stockReceiptItemId }]),
        }),
        uploadInvoice: builder.mutation({
            query: (file) => {
                const formData = new FormData();
                formData.append('file', file);
                return {
                    method: 'POST',
                    url: 'stockInvoiceParsing/upload',
                    body: formData,
                };
            },
            transformResponse: ({ cells, ...baseQueryReturnValue }) => {
                return {
                    ...baseQueryReturnValue,
                    cells: cells.slice(0, maxInvoiceRowCount),
                };
            },
        }),
        getParsedInvoiceSettings: builder.query({
            query: () => ({
                method: 'GET',
                url: 'stockInvoiceParsing/settings',
            }),
            /// TODO: to delete later when test empty setting is done.
            // transformResponse: (result: ParsedStockInvoiceSettings) => {
            // 	return {
            // 		invoiceIdCell: undefined,
            // 		dateCell: undefined,
            // 		// articleColumn: {
            // 		// 	position: 1,
            // 		// 	column: undefined,
            // 		// },
            // 		articleColumn: undefined,
            // 		startRow: undefined,
            // 		qtyColumn: undefined,
            // 		priceColumn: undefined,
            // 		brandColumn: undefined,
            // 		nameColumn: undefined,
            // 		countryCodeColumn: undefined,
            // 		gtdColumn: undefined,
            // 		trackingCodeColumn: undefined,
            // 		trackingQtyColumn: undefined,
            // 		trackingSymbolColumn: undefined,
            // 	} as any as ParsedStockInvoiceSettings;
            // },
        }),
        editParsedInvoiceSettings: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: 'stockInvoiceParsing/settings',
                body,
            }),
        }),
        processInvoice: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: 'stockInvoiceParsing/process',
                body,
            }),
            invalidatesTags: [{ type: 'Invoices', id: 'LIST' }],
        }),
    }),
});
export const { useGetStockInvoicesQuery, useGetStockReceiptItemsQuery } = api;
export { api as stockReceiptApi };
