import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { TextField } from 'core/ui';
import { Tooltip } from '@mui/material';
import { rusLetterOnlyRegex } from 'core/utils/validation';
import { t } from 'shared/translations';
import { useReceiptItemTableStore } from './ReceiptItemTable.state';
/**
 * This search text field is required to be used with ReceiptItemTable component only.
 *
 * @param searchTextFieldRef required
 * @returns
 */
const ReceiptItemSearchField = ({ searchTextFieldRef, disabled, onEnterKeyDown, onDeleteKeyDown, onMinusKeyDown, onPlusKeyDown }) => {
    const searchTerm = useReceiptItemTableStore((state) => state.sharedArticleLocalSearchTerm);
    const currentItemIndex = useReceiptItemTableStore((state) => state.currentItemIndex);
    const setShouldMoveRowSelectionTo = useReceiptItemTableStore((state) => state.setShouldMoveRowSelectionTo);
    const setSearchTerm = useReceiptItemTableStore((state) => state.setSharedArticleLocalSearchTerm);
    const [isWrongLanguage, setIsWrongLanguage] = useState(false);
    useEffect(() => {
        // Prevent search term value to remain in text field when another page, using the same SearchField, has been mounted.
        return () => {
            setSearchTerm('');
        };
    }, []);
    const handleKeyDown = (event) => {
        // // TODO: to delete
        // console.log('event.key', event.key);
        // console.log('event.code', event.code);
        if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
            event.preventDefault();
            setShouldMoveRowSelectionTo(event.code === 'ArrowDown' ? 1 : -1);
            return;
        }
        if (event.code === 'Enter') {
            // Pass through even if currentItem is undefined to play error sound.
            onEnterKeyDown(currentItemIndex === -1 ? undefined : currentItemIndex);
            return;
        }
        if (event.code === 'Delete' && event.ctrlKey && onDeleteKeyDown) {
            if (currentItemIndex > -1)
                onDeleteKeyDown(currentItemIndex);
            return;
        }
        if ((event.code === 'Equal' || event.code === 'NumpadAdd') && onPlusKeyDown) {
            // For +/=
            event.preventDefault();
            if (currentItemIndex > -1)
                onPlusKeyDown(currentItemIndex);
            return;
        }
        if ((event.code === 'Minus' || event.code === 'NumpadSubtract') && onMinusKeyDown) {
            // For -/_
            event.preventDefault();
            if (currentItemIndex > -1)
                onMinusKeyDown(currentItemIndex);
            return;
        }
        if (rusLetterOnlyRegex.test(event.key)) {
            // There are some non-english-letter articles, so only a warning tooltip is to be shown.
            // event.preventDefault();
            setIsWrongLanguage(true);
            return;
        }
        else {
            setIsWrongLanguage(false);
            return;
        }
    };
    return (_jsx(Tooltip, { arrow: true, open: isWrongLanguage, title: t('switch.to.english.language'), slotProps: {
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            // moves tooltip up by 6px.
                            offset: [0, -6],
                        },
                    },
                ],
            },
        }, children: _jsx(TextField, { inputRef: searchTextFieldRef, value: searchTerm, disabled: disabled, placeholder: t('search.by.article'), onChange: (event) => setSearchTerm(event.target.value.toUpperCase()), onKeyDown: handleKeyDown, onFocus: (event) => {
                event.currentTarget.setSelectionRange(event.currentTarget.value.length, event.currentTarget.value.length);
            } }) }));
};
export { ReceiptItemSearchField };
