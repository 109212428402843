export var DetailArticlePosition;
(function (DetailArticlePosition) {
    /** Whole string is article. */
    DetailArticlePosition[DetailArticlePosition["WholeString"] = 1] = "WholeString";
    /** In the start of the string, divided by whitespace. */
    DetailArticlePosition[DetailArticlePosition["CellStart"] = 2] = "CellStart";
    /** In the end of the string, divided by whitespace. */
    DetailArticlePosition[DetailArticlePosition["CellEnd"] = 3] = "CellEnd";
    /** In round brackets, anywhere in the line. */
    DetailArticlePosition[DetailArticlePosition["InRoundBrackets"] = 4] = "InRoundBrackets";
})(DetailArticlePosition || (DetailArticlePosition = {}));
