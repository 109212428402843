export const getPositionState = (quantity, receiptedQuantity, blockedQuantity) => {
    // TODO: to delete later
    // if (blockedQuantity > 0) return 'blocked';
    if (blockedQuantity !== undefined && blockedQuantity > 0)
        return 'blocked';
    if (quantity === receiptedQuantity)
        return 'complete';
    if (receiptedQuantity > 0)
        return 'incomplete';
    return null;
};
