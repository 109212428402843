import { StickerState } from 'shared/models';
import { t } from 'shared/translations';
export const getStickerStateName = (stickerState) => {
    switch (stickerState) {
        case StickerState.Receipted:
            return t('receipted.label');
        case StickerState.Placed:
            return t('placed.label');
        // TODO: to delete later
        // case StickerState.Picked:
        // 	return t('picked.label');
        case StickerState.Packed:
            return t('packed.label');
        case StickerState.AddedToRealization:
            return t('added.to.realization.label');
        case StickerState.Shipped:
            return t('shipped.label');
        case (StickerState.PlacedOnStock,
            StickerState.ReturnFromCustomer,
            StickerState.CanceledByCustomer,
            StickerState.ReceivedAtDeliveryPoint,
            StickerState.HandedToCustomer,
            StickerState.SentToPartnerDeliveryService):
            return 'Unknown sticker status';
        default:
            return 'Unknown receipt status';
    }
};
export const isStickerStateRevokable = (stickerState) => stickerState < StickerState.Packed;
