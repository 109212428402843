import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { packingApi, packingStateReducer } from './modules/packing/business';
import { placementApi, placementStateReducer } from './modules/placement/business';
import { receiptApi, receiptStateReducer } from './modules/receipt/crossdocking/business';
import { stockApi, stockStateReducer } from './modules/stock/business';
import { stockReceiptApi, stockReceiptStateReducer } from './modules/receipt/stock/business';
import { authApi } from 'shared/modules/auth/business';
import { isDevelopmentMode } from 'shared/helpers';
import { shipmentApi } from 'shared/modules/shipment/business';
// import { signalrMiddleware } from './signalrMiddleware';
const rootReducer = combineReducers({
    stock: stockStateReducer,
    receipt: receiptStateReducer,
    stockReceipt: stockReceiptStateReducer,
    placement: placementStateReducer,
    packing: packingStateReducer,
    [authApi.reducerPath]: authApi.reducer,
    [stockApi.reducerPath]: stockApi.reducer,
    [receiptApi.reducerPath]: receiptApi.reducer,
    [stockReceiptApi.reducerPath]: stockReceiptApi.reducer,
    [placementApi.reducerPath]: placementApi.reducer,
    [packingApi.reducerPath]: packingApi.reducer,
    [shipmentApi.reducerPath]: shipmentApi.reducer,
});
// From https://redux-toolkit.js.org/api/getDefaultMiddleware.
// It is preferable to use the chainable .concat(...) and .prepend(...) methods of the returned Tuple instead of the array spread
// operator, as the latter can lose valuable TS type information under some circumstances.
export const store = configureStore({
    reducer: rootReducer,
    devTools: isDevelopmentMode(),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
        // .prepend(signalrMiddleware)
        .concat(authApi.middleware, stockApi.middleware, receiptApi.middleware, stockReceiptApi.middleware, placementApi.middleware, packingApi.middleware, shipmentApi.middleware),
});
if (isDevelopmentMode() && module.hot) {
    module.hot.accept(() => store.replaceReducer(rootReducer));
}
