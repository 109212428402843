import { jsx as _jsx } from "react/jsx-runtime";
import { NoAccess, NotFound } from 'shared/components';
import { Navigate } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';
// Multiple Routes in a single file.
// If you want to avoid loading multiple chunks for **nested** routes, you could store them all in the same file and return them to the individual routes.
// Modern bundlers will latch onto the same Promise for the different import() invocations.
// Example below: <CrossdockingReceipt /> and <CrossdockingReceiptItems /> both have the same import path and therefore have a single chunk.
export const router = createBrowserRouter([
    {
        path: 'login',
        lazy: async () => {
            const { Login } = await import('shared/modules/auth/Login');
            return { Component: Login };
        },
    },
    {
        path: '/',
        // element: (
        // 	<RequireAuth>
        // 		<Layout sidebarNavigationItems={sidebarNavigationItems} />
        // 	</RequireAuth>
        // ),
        lazy: async () => {
            const { AuthorizedLayout } = await import('warehouse/navigation/AuthorizedLayout');
            return { Component: AuthorizedLayout };
        },
        children: [
            // // New blog index route
            // { index: true, Component: () => <h1>Blog Index</h1> },
            // // Blog subapp splat route added for /blog/posts matching
            // { path: "*", Component: BlogApp },
            { path: '/', element: _jsx(Navigate, { to: "receipt/cross", replace: true }) },
            // TODO: to delete later (good working solution without signalR added)
            // { path: 'receipt/cross', element: <Navigate to="invoice" replace /> },
            // {
            // 	path: 'receipt/cross/:tab',
            // 	lazy: async () => {
            // 		const { Receipt, ApproveReceiptItemDialog, BlockReceiptItemDialog } = await import('warehouse/modules/receipt');
            // 		return {
            // 			element: (
            // 				<>
            // 					<Receipt />
            // 					<ApproveReceiptItemDialog />
            // 					<BlockReceiptItemDialog />
            // 				</>
            // 			),
            // 		};
            // 	},
            // },
            // {
            // 	path: 'receipt/cross/:tab/:invoiceId',
            // 	lazy: async () => {
            // 		const { Invoice } = await import('warehouse/modules/receipt/components/Inbox');
            // 		const { ApproveReceiptItemDialog, BlockReceiptItemDialog, RevokeReceiptItemDialog } = await import('warehouse/modules/receipt');
            // 		return {
            // 			element: (
            // 				<>
            // 					<Invoice />
            // 					<ApproveReceiptItemDialog />
            // 					<BlockReceiptItemDialog />
            // 					<RevokeReceiptItemDialog />
            // 				</>
            // 			),
            // 		};
            // 	},
            // },
            { path: 'receipt/cross', element: _jsx(Navigate, { to: "inbox", replace: true }) },
            {
                path: 'receipt/cross',
                lazy: async () => {
                    const { CrossdockingReceiptContainer } = await import('warehouse/modules/receipt');
                    return { Component: CrossdockingReceiptContainer };
                },
                children: [
                    {
                        path: ':tab',
                        lazy: async () => {
                            const { CrossdockingReceipt } = await import('warehouse/modules/receipt');
                            return { Component: CrossdockingReceipt };
                        },
                    },
                    {
                        path: ':tab/:invoiceId',
                        lazy: async () => {
                            const { Invoice } = await import('warehouse/modules/receipt/crossdocking/components/Invoice');
                            return { Component: Invoice };
                        },
                    },
                ],
            },
            { path: 'placement', element: _jsx(Navigate, { to: "receipted", replace: true }) },
            {
                path: 'placement',
                lazy: async () => {
                    const { Placement } = await import('warehouse/modules/placement');
                    return { Component: Placement };
                },
                children: [
                    {
                        path: ':tab',
                        lazy: async () => {
                            const { Placement } = await import('warehouse/modules/placement');
                            return { Component: Placement };
                        },
                    },
                ],
            },
            {
                path: 'packing',
                lazy: async () => {
                    const { Packing } = await import('warehouse/modules/packing');
                    return { Component: Packing };
                },
            },
            {
                path: 'packing/:packingType/:customerId/:legalId',
                lazy: async () => {
                    const { PackingDetails } = await import('warehouse/modules/packing');
                    return { Component: PackingDetails };
                },
            },
            {
                path: 'shipment',
                lazy: async () => {
                    const { Shipment } = await import('warehouse/modules/shipment');
                    return { Component: Shipment };
                },
            },
            { path: 'receipt/stock', element: _jsx(Navigate, { to: "inbox", replace: true }) },
            {
                path: 'receipt/stock',
                lazy: async () => {
                    const { StockReceiptContainer } = await import('warehouse/modules/receipt');
                    return { Component: StockReceiptContainer };
                },
                children: [
                    {
                        path: ':tab',
                        lazy: async () => {
                            const { StockReceipt } = await import('warehouse/modules/receipt');
                            return { Component: StockReceipt };
                        },
                    },
                    {
                        path: ':tab/:invoiceId',
                        lazy: async () => {
                            const { Invoice } = await import('warehouse/modules/receipt/stock/components/Invoice');
                            return { Component: Invoice };
                        },
                    },
                ],
            },
            {
                path: 'stock',
                //element: <StockReceipt /> ,
                // TODO: allow access only to 'Warehouse manager' (uncomment)
                // element: <ProtectedRoute allow={'Warehouse'} />,
                lazy: async () => {
                    const { StockList } = await import('warehouse/modules/stock');
                    return { Component: StockList };
                },
            },
            {
                path: 'stock/:stockId',
                //element: <StockReceiptItems /> ,
                lazy: async () => {
                    const { Stock } = await import('warehouse/modules/stock');
                    return { Component: Stock };
                },
            },
            {
                path: 'settings',
                lazy: async () => {
                    const { Settings } = await import('warehouse/modules/settings');
                    return { Component: Settings };
                },
            },
            { path: 'access', element: _jsx(NoAccess, {}) },
            { path: '*', element: _jsx(NotFound, {}) },
        ],
    },
]);
