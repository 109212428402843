export const maxSnackbars = 2;
export const snackbarAutoHideDuration = 8000;
export const receiptSnackbarAutoHideDuration = 5000;
export const warehouseAppSidebarWidth = '188px';
/**
 *  Maximum allowed price list file size (in bytes) to be uploaded.
 *
 *  50 Mb limit.
 *
 *  Just copied from AdminApp `piceListFileMaxSizeToUpload` constant.
 */
export const maxUploadFileSize = 50 * 1024 * 1024;
