import { jsx as _jsx } from "react/jsx-runtime";
import { Step, Stepper, useTheme } from '@mui/material';
import { StepLabelButton, StepLabelIconContainer } from './BreadcrumbStepper.styled';
import { SvgIcon } from '../SvgIcon';
import circleCheckIcon from 'core/svg/fa/circle-check.svg';
import circleDotIcon from 'core/svg/fa/circle-dot.svg';
import circleSmallIcon from 'core/svg/fa/circle-small.svg';
const BreadcrumbStepper = ({ steps, activeStep, stepDisableConditions, onStepClick }) => {
    const { palette } = useTheme();
    const isStepDisabled = (stepIndex) => {
        if (!stepDisableConditions)
            return false;
        return Boolean(stepDisableConditions.find((condition) => condition.stepIndex === stepIndex)?.disabled);
    };
    const handleStepLabelClick = (stepIndex) => () => {
        onStepClick?.(stepIndex);
    };
    return (_jsx(Stepper, { orientation: "horizontal", activeStep: activeStep, children: steps?.map((step) => {
            const disabled = stepDisableConditions && isStepDisabled(step.index);
            return (_jsx(Step, { active: step.index === activeStep, completed: step.index < activeStep, disabled: disabled, children: _jsx(StepLabelButton, { icon: _jsx(StepLabelIconContainer, { children: step.index < activeStep ? (_jsx(SvgIcon, { icon: circleCheckIcon, size: "md", color: palette.primary.dark })) : step.index === activeStep ? (_jsx(SvgIcon, { icon: circleDotIcon, size: "md", color: palette.primary.dark })) : (_jsx(SvgIcon, { icon: circleSmallIcon, size: "3xs" })) }), onClick: disabled ? undefined : handleStepLabelClick(step.index), children: step.label }) }, step.index));
        }) }));
};
export { BreadcrumbStepper };
