import { createSlice } from '@reduxjs/toolkit';
import { StickerState } from 'shared/models';
const initialState = {
    searchTerm: '',
    customerPackingSelectDialog: {
        open: false,
        selectedCustomerIds: [],
    },
    packingDetails: {
        currentBoxId: undefined,
        progressPercent: undefined,
    },
};
const slice = createSlice({
    name: 'packing',
    initialState,
    reducers: {
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        openCustomerPackingSelectDialog: (state) => {
            state.customerPackingSelectDialog = {
                ...initialState.customerPackingSelectDialog,
                open: true,
            };
        },
        closeCustomerPackingSelectDialog: (state) => {
            state.customerPackingSelectDialog = initialState.customerPackingSelectDialog;
        },
        setCurrentBox: (state, action) => {
            state.packingDetails.currentBoxId = action.payload.deliveryBoxId;
        },
        calculateProgressPercent: (state, action) => {
            const { stickers } = action.payload;
            if (!stickers) {
                state.packingDetails.progressPercent = 0;
            }
            else {
                const progress = stickers.reduce((result, { quantity, state }) => {
                    result.all += quantity;
                    if (state === StickerState.Packed)
                        result.done += quantity;
                    return result;
                }, { all: 0, done: 0 });
                state.packingDetails.progressPercent = progress.all === 0 ? 0 : Math.floor((progress.done / progress.all) * 100);
            }
        },
        changeCustomerMark: (state, action) => {
            const { selectedCustomerIds } = state.customerPackingSelectDialog;
            const { customerId, marked } = action.payload;
            if (marked) {
                if (!selectedCustomerIds.includes(customerId))
                    state.customerPackingSelectDialog.selectedCustomerIds = [...selectedCustomerIds, customerId];
            }
            else if (selectedCustomerIds.includes(customerId)) {
                state.customerPackingSelectDialog.selectedCustomerIds = selectedCustomerIds.filter((el) => el !== customerId);
            }
        },
        // TODO: to delete later if unused
        // markCustomer: (state, action: PayloadAction<number>) => {
        // 	if (state.customerPackingSelectDialog.selectedCustomerIds.includes(action.payload)) return;
        // 	state.customerPackingSelectDialog.selectedCustomerIds = [...state.customerPackingSelectDialog.selectedCustomerIds, action.payload];
        // },
        // unmarkCustomer: (state, action: PayloadAction<number>) => {
        // 	if (!state.customerPackingSelectDialog.selectedCustomerIds.includes(action.payload)) return;
        // 	state.customerPackingSelectDialog.selectedCustomerIds = state.customerPackingSelectDialog.selectedCustomerIds.filter(
        // 		(el) => el !== action.payload
        // 	);
        // },
    },
});
export const { actions: packingActions, reducer: packingStateReducer } = slice;
