import { jsx as _jsx } from "react/jsx-runtime";
import { QuantityChip } from '../shared.styled';
import { t } from 'shared/translations';
const AvailableQuantityChip = ({ availableQuantity, onClick, onDoubleClick }) => {
    const handleDoubleClick = (event) => {
        // Prevents *row* double click event.
        event.stopPropagation();
    };
    return (_jsx(QuantityChip, { clickable: true, size: "medium", variant: "filled", color: "secondary", label: availableQuantity, title: `${t('accept.label')} (Enter)`, tabIndex: -1, onClick: onClick, onDoubleClick: onDoubleClick ?? handleDoubleClick }));
};
export { AvailableQuantityChip };
