import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { createAppAsyncThunk } from 'warehouse/helpers';
import { getErrorFromException } from 'core/utils/errorHandling';
import { receiptApprovementSnackbarDuration } from 'warehouse/modules/receipt/shared/constants';
import { stockReceiptApi } from '../stockReceipt.api';
import { t } from 'shared/translations';
import { useReceiptItemTableStore } from 'warehouse/modules/receipt/shared/ReceiptItemTable.state';
const setSearchTerm = useReceiptItemTableStore.getState().setSharedArticleLocalSearchTerm;
const placeStockReceiptItem = createAppAsyncThunk('stockReceipt/placeStockReceiptItem', async ({ receiptItem, brand, article }, { getState, dispatch, rejectWithValue }) => {
    try {
        // A single snackbar is allowed at one moment.
        closeSnackbar();
        // await dispatch(stockReceiptApi.endpoints.confirmReceiptItem.initiate(receiptItem)).unwrap();
        await dispatch(stockReceiptApi.endpoints.placeInStock.initiate(receiptItem)).unwrap();
        enqueueSnackbar({
            // Should not prevent duplicate since another quantity of the same article may be confirmed.
            // key: receiptItem.receiptItemId,
            // preventDuplicate: true,
            variant: 'receiptSubmissionSuccess',
            brand,
            article,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            autoHideDuration: receiptApprovementSnackbarDuration,
        });
        // Clear search text field.
        setSearchTerm('');
    }
    catch (error) {
        const errorData = error.data;
        if (errorData?.Message?.includes('The received quantity more that available')) {
            // TODO: later. Get rid of this when new backend will be ready.
            enqueueSnackbar({
                variant: 'error',
                header: t('receipt.item.reception.failed'),
                body: t('received.quantity.more.than.available'),
                persist: false,
            });
        }
        else {
            enqueueSnackbar({
                variant: 'error',
                header: t('receipt.item.reception.failed'),
                body: getErrorFromException(error),
                persist: true,
            });
        }
    }
});
export { placeStockReceiptItem };
