import { createAppAsyncThunk } from 'warehouse/helpers';
import { enqueueSnackbar } from 'notistack';
import { getErrorFromException } from 'core/utils/errorHandling';
import { selectAllSettingsDefined } from '../stockReceipt.state';
import { stockReceiptApi } from '../stockReceipt.api';
import { t } from 'shared/translations';
const processInvoice = createAppAsyncThunk('stockReceipt/processInvoice', async ({ stockId }, { getState, dispatch, rejectWithValue, fulfillWithValue }) => {
    // TODO: Try to get rid of full state call
    const state = getState();
    const { invoice, invoiceSettings } = state.stockReceipt.uploadInvoiceDialog;
    const allSettingsDefined = selectAllSettingsDefined(state);
    if (!invoice || !invoiceSettings)
        return;
    if (!allSettingsDefined) {
        enqueueSnackbar({
            variant: 'info',
            header: t('one.of.settings.is.not.defined'),
            body: t('you.should.define.all.invoice.settings.first'),
        });
        return;
    }
    try {
        await dispatch(stockReceiptApi.endpoints.editParsedInvoiceSettings.initiate(invoiceSettings)).unwrap();
    }
    catch (error) {
        const errorMessage = getErrorFromException(error);
        enqueueSnackbar({
            variant: 'error',
            header: t('stock.receipt.invoice.settings.upload.failed'),
            body: errorMessage,
            persist: true,
        });
        // return rejectWithValue(errorMessage);
        return;
    }
    try {
        await dispatch(stockReceiptApi.endpoints.processInvoice.initiate({ stockId, fileCashId: invoice.fileCashId })).unwrap();
    }
    catch (error) {
        const errorMessage = getErrorFromException(error);
        enqueueSnackbar({
            variant: 'error',
            header: t('stock.receipt.get.parsed.invoice.settings.failed'),
            body: errorMessage,
            persist: true,
        });
        // return rejectWithValue(errorMessage);
        return;
    }
    return fulfillWithValue('success');
});
export { processInvoice };
