import { createSlice } from '@reduxjs/toolkit';
import { confirmReceiptItem } from './thunk/confirmReceiptItem';
import { isStickerStateRevokable } from 'warehouse/helpers';
const initialState = {
    inboxDateRangeFilter: {
        // Should always be `null`, otherwise it fires additional request with this initial values right before the
        // necessary request with values gotten from local storage.
        dateFrom: null,
        dateTo: null,
    },
    inboxArticleSearchTerm: '',
    inboxItemCount: undefined,
    verificationItemCount: undefined,
    currentItemIndex: -1,
    shouldMoveRowSelectionTo: 0,
    ownerLocation: 'invoice',
    approveReceiptItemDialog: {
        open: false,
        data: undefined,
        availableQuantity: 0,
        isError: false,
        error: undefined,
    },
    revokeReceiptItemDialog: {
        open: false,
        data: undefined,
        markedStickerItemIds: [],
    },
    blockReceiptItemDialog: {
        open: false,
        data: undefined,
    },
};
const slice = createSlice({
    name: 'receipt',
    initialState,
    reducers: {
        setInboxDateRangeFilter: (state, action) => {
            state.inboxDateRangeFilter = action.payload;
        },
        setInboxItemCount: (state, action) => {
            state.inboxItemCount = action.payload;
        },
        setVerificationItemCount: (state, action) => {
            state.verificationItemCount = action.payload;
        },
        setInboxArticleSearchTerm: (state, action) => {
            state.inboxArticleSearchTerm = action.payload;
        },
        setCurrentItemIndex: (state, action) => {
            state.currentItemIndex = action.payload;
        },
        setShouldMoveRowSelectionTo: (state, action) => {
            state.shouldMoveRowSelectionTo = action.payload;
        },
        setOwnerLocation: (state, action) => {
            state.ownerLocation = action.payload;
        },
        openApproveReceiptItemDialog: (state, action) => {
            state.approveReceiptItemDialog = {
                ...state.approveReceiptItemDialog,
                open: true,
                data: {
                    ...action.payload.receiptItemConfirm,
                    isBlocked: false,
                },
                availableQuantity: action.payload.availableQuantity,
            };
        },
        closeApproveReceiptItemDialog: (state) => {
            state.approveReceiptItemDialog = initialState.approveReceiptItemDialog;
        },
        openRevokeReceiptItemDialog: (state, action) => {
            state.revokeReceiptItemDialog.open = true;
            state.revokeReceiptItemDialog.data = action.payload;
        },
        markStickerItem: (state, action) => {
            const markedIds = state.revokeReceiptItemDialog.markedStickerItemIds;
            const id = action.payload.stickerItemId;
            state.revokeReceiptItemDialog.markedStickerItemIds = markedIds.includes(id)
                ? markedIds.filter((markedId) => markedId !== id)
                : [...markedIds, id];
        },
        markAll: (state, action) => {
            const markedIds = state.revokeReceiptItemDialog.markedStickerItemIds;
            const revokableStickers = action.payload.stickerItems.filter((stickerItem) => isStickerStateRevokable(stickerItem.state));
            state.revokeReceiptItemDialog.markedStickerItemIds =
                markedIds.length === revokableStickers.length ? [] : revokableStickers.map((sticker) => sticker.stickerItemId);
        },
        closeRevokeReceiptItemDialog: (state) => {
            state.revokeReceiptItemDialog = initialState.revokeReceiptItemDialog;
        },
        openBlockReceiptItemDialog: (state, action) => {
            const { receiptItemId, article, brand, invoiceId, name, quantity, receiptedQuantity, blockedQuantity, userName } = action.payload;
            // TODO: (later) Nicer to use function like lodash's pick, or:
            // const picked = (({ receiptItemId, article, brand, invoiceId, name, quantity, userName }) => ({
            // 	receiptItemId,
            // 	article,
            // 	brand,
            // 	invoiceId,
            // 	name,
            // 	quantity,
            // 	userName,
            // }))(action.payload);
            state.blockReceiptItemDialog.open = true;
            state.blockReceiptItemDialog.data = {
                receiptItemId,
                article,
                brand,
                invoiceId,
                name,
                availableQuantity: quantity - receiptedQuantity - blockedQuantity,
                userName,
                isBlocked: true,
            };
        },
        closeBlockReceiptItemDialog: (state) => {
            state.blockReceiptItemDialog = initialState.blockReceiptItemDialog;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(confirmReceiptItem.rejected, (state, action) => {
            state.approveReceiptItemDialog.isError = true;
            state.approveReceiptItemDialog.error = action.error;
        });
    },
});
export const { actions: receiptActions, reducer: receiptStateReducer } = slice;
